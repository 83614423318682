import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get, child, query, orderByChild, equalTo, update, remove, onValue } from 'firebase/database';
import logger from './logger';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const dbVer = process.env.FIREBASE_DB_VERSION || '08032024';

export async function getUserConfig(userId: string): Promise<UserConfig | null> {
    const db = getDatabase();
    const userConfigRef = ref(db, `${dbVer}/users/${userId}/config`);

    try {
        const snapshot = await get(userConfigRef);
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            logger.log('No user config found.');
            return null;
        }
    } catch (error) {
        logger.error('Error fetching user config:', error);
        return null;
    }
}

export async function updateUserConfig(userId: string, config: {
    columns?: number;
    rows?: number;
    gap?: number;
    showGrid?: boolean;
    theme?: string;
    widgets?: any;
}) {
    const db = getDatabase();
    const userConfigRef = ref(db, `${dbVer}/users/${userId}/config`);

    try {
        await update(userConfigRef, config);
        logger.log('User config updated successfully:', config);

        // Fetch and log the updated config
        const updatedConfig = await getUserConfig(userId);
        logger.log('Current user config in Firebase:', updatedConfig);
    } catch (error) {
        logger.error('Error updating user config:', error);
        throw error;
    }
}

// New util method for updating the user's widget only
export async function updateUserWidget(userId: string, widgetId: string, updatedWidget: any) {
    const db = getDatabase();
    const widgetRef = ref(db, `${dbVer}/users/${userId}/config/widgets/${widgetId}`);

    try {
        await set(widgetRef, updatedWidget);
        logger.log('Widget updated successfully.');
    } catch (error) {
        logger.error('Error updating widget:', error);
        throw error;
    }
}

export async function removeUserWidget(userId: string, widgetId: string) {
    const db = getDatabase();
    const widgetRef = ref(db, `${dbVer}/users/${userId}/config/widgets/${widgetId}`);

    try {
        await remove(widgetRef);
        logger.log('Widget removed successfully.');
    } catch (error) {
        logger.error('Error removing widget:', error);
        throw error;
    }
}

export async function checkEmailUniqueness(email: string): Promise<boolean> {
    const usersRef = ref(db, `${dbVer}/users`);
    const emailQuery = query(usersRef, orderByChild('email'), equalTo(email));
    const querySnapshot = await get(emailQuery);
    return !querySnapshot.exists();
}

export async function addUserToFirebase(userConfig: any) {
    const userRef = ref(db, `${dbVer}/users/${userConfig.id}`);
    await set(userRef, userConfig);
}

export function listenForConfigChanges(userId: string, applySettings: (config: any) => void, loadUserWidgets: () => void) {
    if (!userId) return;

    const userConfigRef = ref(db, `${dbVer}/users/${userId}/config`);

    onValue(userConfigRef, (snapshot) => {
        const config = snapshot.val();
        if (config) {
            // Update grid settings if they've changed
            applySettings(config);

            // Render widgets
            if (config.widgets) {
                loadUserWidgets();
            }
        }
    });
}