class Logger {
    private debugMode: boolean;

    constructor() {
        this.debugMode = process.env.DEBUG === 'true';
    }

    private getCallerInfo() {
        const error = new Error();
        const stack = error.stack?.split('\n') || [];
        // The caller information is usually at the 3rd position in the stack trace
        const callerStackLine = stack[3] || '';
        const match = callerStackLine.match(/at\s+(.*)\s+\((.*):(\d+):(\d+)\)/) || callerStackLine.match(/at\s+(.*):(\d+):(\d+)/);
        if (match) {
            const methodName = match[1];
            const filePath = match[2];
            const lineNumber = match[3];
            return `${methodName} - ${filePath}:${lineNumber}`;
        }
        return 'unknown';
    }

    log(...args: any[]) {
        if (this.debugMode) {
            const callerInfo = this.getCallerInfo();
            console.log(...args, `[${callerInfo}]`);
        }
    }

    warn(...args: any[]) {
        if (this.debugMode) {
            const callerInfo = this.getCallerInfo();
            console.warn(...args, `[${callerInfo}]`);
        }
    }

    error(...args: any[]) {
        if (this.debugMode) {
            const callerInfo = this.getCallerInfo();
            console.error(...args, `[${callerInfo}]`);
        }
    }
}

export default new Logger();