import { CUSTOM_EVENTS } from "../utils/const";
import { dispatchCustomEvent } from "../utils/customEvents";
import logger from "../utils/logger";

export class FloatingActionButton extends HTMLElement {
    private button: HTMLButtonElement;
    private iconElement: HTMLSpanElement;

    constructor() {
        super();
        this.button = document.createElement('button');
        this.button.classList.add('md-fab', 'ripple');
        this.iconElement = document.createElement('span');
        this.iconElement.classList.add('md-fab-icon', 'material-icons');
        this.iconElement.textContent = '⚙️';
        this.button.appendChild(this.iconElement);
        this.appendChild(this.button);
        this.button.id = 'fab-btn';
    }

    connectedCallback() {
        this.setupEventListeners();
    }

    disconnectedCallback() {
        this.removeEventListeners();
    }

    private setupEventListeners() {
        this.button.addEventListener('click', this.handleClick);
    }

    private removeEventListeners() {
        this.button.removeEventListener('click', this.handleClick);
    }

    private handleClick = (event: Event) => {
        logger.log('FloatingActionButton handleClick() method called');
        event.stopPropagation();
        dispatchCustomEvent(CUSTOM_EVENTS.fabClick);
    }

    static get observedAttributes() {
        return ['icon', 'color'];
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'icon' && oldValue !== newValue) {
            this.updateIcon(newValue);
        } else if (name === 'color' && oldValue !== newValue) {
            this.updateColor(newValue);
        }
    }

    private updateIcon(icon: string) {
        this.iconElement.textContent = icon || '⚙️';
    }

    private updateColor(color: string) {
        if (color === 'secondary') {
            this.button.classList.add('md-fab-secondary');
        } else {
            this.button.classList.remove('md-fab-secondary');
        }
    }
}