import { CUSTOM_EVENTS } from "../utils/const";
import { dispatchCustomEvent } from "../utils/customEvents";

export class WidgetActionButton extends HTMLElement {
    private button: HTMLButtonElement;
    private _widgetId: string = '';
    private _action: string = '';

    static get observedAttributes() {
        return ['widget-id', 'action', 'icon', 'title'];
    }

    constructor() {
        super();
        this.button = document.createElement('button');
        this.button.classList.add('widget-action-button');
    }

    connectedCallback() {
        this.appendChild(this.button);
        this.button.addEventListener('click', this.handleClick.bind(this));
    }

    disconnectedCallback() {
        this.button.removeEventListener('click', this.handleClick.bind(this));
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (oldValue === newValue) return;

        switch (name) {
            case 'widget-id':
                this._widgetId = newValue;
                break;
            case 'action':
                this._action = newValue;
                break;
            case 'icon':
                this.button.innerHTML = newValue;
                break;
            case 'title':
                this.button.title = newValue;
                break;
        }
    }

    private handleClick(e: Event) {
        e.stopPropagation();
        dispatchCustomEvent(CUSTOM_EVENTS.widgetAction, { widgetId: this._widgetId, action: this._action });
    }
}

customElements.define('widget-action-button', WidgetActionButton);