import * as components from '../components';
import logger from './logger';

let componentsLoaded = false;
let componentInstances: ComponentInstances = {};

// TODO: Move to utils if it is still needed
const kebabCase = (str: string) =>
    str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const registerComponent = (name, component) => {
    if (typeof component === 'function' && !customElements.get(kebabCase(name))) {
        customElements.define(kebabCase(name), component as ComponentConstructor);
    }
};

export const createComponentInstance = (name, Component) => {
    if (typeof Component === 'function') {
        // Check if an instance already exists in the DOM
        const existingInstance = document.querySelector(kebabCase(name));
        if (existingInstance) {
            logger.log(`Using existing ${name} instance from the DOM`);
            componentInstances[name] = existingInstance;
        } else if (!componentInstances[name]) {
            componentInstances[name] = new (Component as ComponentConstructor)();
            logger.log(`Component instance created for: ${name}`);
        } else {
            logger.log(`Component instance already exists for: ${name}`);
        }
    }
};

export const componentLoader = () => {
    try {
        if (!componentsLoaded) {
            Object.entries(components).forEach(([name, Component]) => {
                registerComponent(name, Component);
                createComponentInstance(name, Component);
            });

            componentsLoaded = true;
            componentInstances?.LoadingSpinner?.updateText?.("Loaded components.");
        } else {
            logger.log("Looks like components have already been loaded.");
        }
        return componentInstances;
    } catch (error) {
        logger.error(error);
        return null;
    }
};