import { CUSTOM_EVENTS } from "../utils/const";
import { dispatchCustomEvent, listenForCustomEvent } from "../utils/customEvents";
import logger from "../utils/logger";

export class SettingsMenu extends HTMLElement {
    private container: HTMLDivElement;
    private form: HTMLFormElement;
    private DEFAULT = {
        columns: 3,
        rows: 4,
        gap: 10,
        showGrid: true,
        theme: 'light'
    }

    constructor() {
        super();
        this.container = document.createElement('div');
        this.container.id = 'settings-menu';
        this.container.className = 'md-settings-menu';
        this.form = document.createElement('form');
        this.toggle = this.toggle.bind(this);


        this.render();
        this.setupEventListeners();
    }

    connectedCallback() {
        this.appendChild(this.container);
    }

    toggle() {
        logger.log('SettingsMenu toggle() method called');
        if (this.container.classList.contains('visible')) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        logger.log('SettingsMenu show() method called');
        this.container.classList.add('visible');
    }

    hide() {
        logger.log('SettingsMenu hide() method called');
        this.container.classList.remove('visible');
    }

    private render() {
        this.form.innerHTML = /*html*/`
            <h2 class="md-settings-title">Settings</h2>
            <div class="md-form-field">
                <input type="number" id="columns-input" name="columns" min="1" max="24" class="md-input" placeholder=" " value=${this.DEFAULT.columns}>
                <label for="columns-input">Columns</label>
            </div>
            <div class="md-form-field">
                <input type="number" id="rows-input" name="rows" min="1" max="24" class="md-input" placeholder=" " value=${this.DEFAULT.rows}>
                <label for="rows-input">Rows</label>
            </div>
            <div class="md-form-field">
                <input type="number" id="gap-input" name="gap" min="0" max="100" class="md-input" placeholder=" " value=${this.DEFAULT.gap}>
                <label for="gap-input">Gap</label>
            </div>
            <div class="md-switch">
                <input type="checkbox" id="show-grid-toggle" name="showGrid" class="md-switch-input" checked=${this.DEFAULT.showGrid}>
                <label for="show-grid-toggle" class="md-switch-label">Show Grid</label>
            </div>
            <div class="md-form-field">
                <select id="theme-select" name="theme" class="md-select" value=${this.DEFAULT.theme}>
                    <option value=""></option>
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                </select>
                <label for="theme-select">Theme</label>
            </div>
        `;
        this.container.appendChild(this.form);
    }

    private setupEventListeners() {
        this.form.addEventListener('input', this.handleInputChange.bind(this));
        listenForCustomEvent(CUSTOM_EVENTS.fabClick, this.handleClick.bind(this));
    }

    private handleClick = (event: Event) => {
        logger.log("Handling fab click at settings menu", event);
        this.toggle();
    }

    private handleInputChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (target.name) {
            this.applySettings();
        }
    }

    private applySettings() {
        const formData = new FormData(this.form);
        const settings = {
            columns: parseInt(formData.get('columns') as string, 10),
            rows: parseInt(formData.get('rows') as string, 10),
            gap: parseInt(formData.get('gap') as string, 10),
            showGrid: formData.get('showGrid') === 'on',
            theme: formData.get('theme') as string
        };

        dispatchCustomEvent(CUSTOM_EVENTS.settingsUpdated, settings);

    }
}