
export function dispatchCustomEvent(eventName: string, eventData?: CustomEventInit["detail"], options?: CustomEventOptions): void {
    const customEvent = new CustomEvent(eventName, {
        bubbles: true,
        composed: true,
        detail: eventData,
        ...options,
    });



    if (options?.targetElement && options?.targetElement instanceof HTMLElement) {
        options?.targetElement.dispatchEvent(customEvent);
    } else {
        document.dispatchEvent(customEvent);
    }
}

export function listenForCustomEvent<T = any>(
    eventName: string,
    handler: (event: CustomEvent<T>) => void,
    options?: CustomEventOptions
): void {
    const eventListener = ((event: Event) => {
        if (event instanceof CustomEvent) {
            handler(event as CustomEvent<T>);
        }
    }) as EventListener;

    if (options?.targetElement && options?.targetElement instanceof HTMLElement) {
        options?.targetElement.addEventListener(eventName, eventListener);
        return;
    } else {
        document.addEventListener(eventName, eventListener);
        return;
    }

}