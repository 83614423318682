import logger from './logger';


export class WidgetLoader {
    private widgetRegistry: Record<string, () => Promise<{ default: CustomElementConstructor; config: WidgetConfig }>> = {};
    private loadedScripts: Map<string, HTMLScriptElement> = new Map();

    constructor() {
        // TODO: check if these paths are still valid
        // Register built-in widgets
        this.registerWidget('clock', () => import('../widgets/clock'));
        // Register other built-in widgets here
        this.registerWidget('weather', () => import('../widgets/weather'));
        this.registerWidget('slideshow', () => import('../widgets/slideshow'));
    }

    registerWidget(name: string, factory: () => Promise<{ default: CustomElementConstructor; config: WidgetConfig }>) {
        this.widgetRegistry[name] = factory;
    }

    async loadWidget(config: { name: string; url?: string; id: string }): Promise<HTMLElement> {
        logger.log(`Loading widget: ${JSON.stringify(config)}`); // Debug log
        try {
            let widgetConfig: WidgetConfig;

            if (config.url) {
                logger.log(`Loading external widget from URL: ${config.url}`); // Debug log
                await this.loadExternalWidget(config.url, config.id);
                return document.createElement(config.id);
            } else if (this.widgetRegistry[config.name]) {
                logger.log(`Loading internal widget: ${config.name}`); // Debug log
                const module = await this.widgetRegistry[config.name]();
                const WidgetClass = module.default;
                widgetConfig = module.config;

                if (!customElements.get(widgetConfig.id)) {
                    customElements.define(widgetConfig.id, WidgetClass);
                    logger.log(`Defined custom element: ${widgetConfig.id}`); // Debug log
                } else {
                    logger.log(`Custom element ${widgetConfig.id} already defined`); // Debug log
                }

                return document.createElement(widgetConfig.id);
            } else {
                throw new Error(`Unknown widget type: ${config.name}`);
            }
        } catch (error) {
            logger.error(`Error loading widget:`, error);
            const errorElement = document.createElement('div');
            errorElement.textContent = `Failed to load widget: ${config.name}`;
            return errorElement;
        }
    }

    private loadExternalWidget(url: string, widgetId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            // Remove any existing script for this widget
            this.removeExternalWidget(widgetId);

            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.id = `widget-script-${widgetId}`;

            script.onload = () => {
                this.loadedScripts.set(widgetId, script);
                resolve();
            };

            script.onerror = () => {
                reject(new Error(`Failed to load script: ${url}`));
            };

            document.head.appendChild(script);
        });
    }

    removeExternalWidget(widgetId: string): void {
        const script = this.loadedScripts.get(widgetId);
        if (script && script.parentNode) {
            script.parentNode.removeChild(script);
            this.loadedScripts.delete(widgetId);
        }

        // Attempt to remove the custom element definition
        // Note: This is not fully supported in all browsers
        if (customElements.get(widgetId)) {
            try {
                // @ts-ignore: This method is not standard and may not be available
                customElements.undefined(widgetId);
            } catch (error) {
                logger.warn(`Unable to undefine custom element: ${widgetId}`, error);
            }
        }
    }

    clearAllExternalWidgets(): void {
        this.loadedScripts.forEach((script, widgetId) => {
            this.removeExternalWidget(widgetId);
        });
    }
}

export const widgetLoader = new WidgetLoader();