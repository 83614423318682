import { getUserConfig, checkEmailUniqueness, addUserToFirebase, updateUserConfig, updateUserWidget, removeUserWidget, listenForConfigChanges } from './utils/firebase';
import { getDatabase, onValue, ref } from 'firebase/database';
import { widgetLoader } from './utils/widgetLoader';
import { generateAlphanumericId } from './utils/idGenerator';
import { componentLoader } from './utils/componentLoader';
import { listenForCustomEvent } from './utils/customEvents';
import { CUSTOM_EVENTS } from './utils/const';
import type { FloatingNav } from './components';
import logger from './utils/logger';

class MoonSuiteBuilder {
    private userId: string | null = null;
    private userConfig: UserConfig | null = null;
    private gridContainer: HTMLElement | null = null;
    private userIdDisplay: HTMLElement | null = null;
    private showGrid: boolean = true;
    private columns: number = 3;
    private rows: number = 4;
    private gap: number = 0;
    private theme: string = 'light';
    private currentEditingWidgetId: string | null = null;
    private components: ComponentInstances;
    private readonly TITLE = 'My Moon';

    constructor() {
        this.gridContainer = document.getElementById('grid-container');
        this.userIdDisplay = document.getElementById('user-id-display');
        this.components = componentLoader() as ComponentInstances;
    }

    async init() {
        try {

            if (!this.components) {
                logger.error('Failed to load components.');
                return;
            }

            this.updateLoadingStatus('Initializing application...');
            await this.initializeUser();
            this.displayUserId();
            await this.loadUserConfig();
            this.setupSettingsMenu(); // Add this line
            this.renderGrid();
            await this.loadUserWidgets();
            this.setupEventListeners();
            this.removeLoadingSpinner();
            this.updateLoadingStatus('Initialization complete.');
        } catch (error) {
            logger.error('Initialization failed:', error);
            this.updateLoadingStatus('Initialization failed. Please try again.');
        }
    }

    private updateLoadingStatus(message: string) {
        const LoadingSpinner = this?.components?.LoadingSpinner;

        const { updateText, show, delayedHide } = LoadingSpinner || {};

        if (updateText && show && delayedHide) {
            show();
            updateText(message);
            delayedHide(1000);
        }
    }

    private async initializeUser() {
        this.updateLoadingStatus('Checking user profile...');
        const userProfile = localStorage.getItem('user_profile');

        if (userProfile) {
            this.userId = userProfile;
            this.updateLoadingStatus('User profile found.');
        } else {
            this.updateLoadingStatus('New user detected. Setting up profile...');
            await this.showFirstTimeUserDialog();
        }
    }

    private displayUserId() {
        if (this.userId && this.userIdDisplay) {
            // Clear any existing content
            this.userIdDisplay.innerHTML = '';

            // Create the paragraph element
            const paragraph = document.createElement('p');
            paragraph.innerText = 'Your ID is: ';

            // Create the strong element
            const strong = document.createElement('strong');

            // Create the span element for the clipboard icon
            const clipboardIcon = document.createElement('span');
            clipboardIcon.id = 'clipboard-icon';
            clipboardIcon.title = 'Copy to clipboard';
            clipboardIcon.innerHTML = '&#128203;';

            // Attach the click event to the strong element
            strong.onclick = () => this.copyToClipboard(strong);
            strong.innerText = this.userId;

            // Append the clipboard icon to the strong element
            strong.appendChild(clipboardIcon);

            // Append the strong element to the paragraph
            paragraph.appendChild(strong);

            // Append the paragraph to the userIdDisplay
            this.userIdDisplay.appendChild(paragraph);

            // Create and append the second paragraph
            const secondParagraph = document.createElement('p');
            secondParagraph.innerText = 'Enter this ID in your TV';
            this.userIdDisplay.appendChild(secondParagraph);

            // Display the userIdDisplay element
            this.userIdDisplay.style.display = 'block';
        }
    }

    private copyToClipboard(element: HTMLElement) {
        const el = document.createElement('textarea');
        el.value = this.userId || '';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        // Temporarily change the text to "Copied to clipboard"
        const originalText = element.innerText;
        element.innerText = 'Copied to clipboard';
        document

        // Revert back to the original text after 2 seconds
        setTimeout(() => {
            element.innerText = originalText;
        }, 2000);
    }

    private getDefaultConfig() {
        return {
            language: 'en', // Add the 'language' property with a default value
            columns: this.columns,
            rows: this.rows,
            gap: this.gap,
            showGrid: this.showGrid,
            theme: this.theme,
            widgets: {}
        };
    }

    private async loadUserConfig() {
        if (!this.userId) {
            logger.log('Tried to load user config without a user ID.');
            return;
        }

        this.updateLoadingStatus('Loading user configuration...');
        const config = await getUserConfig(this.userId);

        if (config) {
            this.userConfig = config;
            this.updateLoadingStatus('User configuration loaded.');
            this.applySettings(config);
            this.populateSettingsMenu(config);
        } else {
            logger.warn('User config not found. Using default settings.');
            this.updateLoadingStatus('Using default configuration.');
            this.userConfig = this.getDefaultConfig();
            this.applySettings(this.userConfig);
            this.populateSettingsMenu(this.userConfig);
        }
    }

    private populateSettingsMenu(config: UserConfig) {
        const settingsInputs = document.querySelectorAll('#settings-menu input, #settings-menu select') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
        settingsInputs.forEach(input => {
            const setting = input.id.replace('-input', '').replace('-select', '');
            if (setting in config) {
                if (input.type === 'checkbox') {
                    (input as HTMLInputElement).checked = config[setting as keyof UserConfig] as boolean;
                } else {
                    input.value = config[setting as keyof UserConfig]?.toString?.() || '';
                }
            }
        });

        // Special handling for the "Show Grid" checkbox
        const showGridToggle = document.getElementById('show-grid-toggle') as HTMLInputElement;
        showGridToggle.checked = config.showGrid;
    }

    private async updateUserSettings(newSettings: UserConfig) {
        if (!this.userId) return;

        try {
            await updateUserConfig(this.userId, newSettings);
            this.applySettings(newSettings);
        } catch (error) {
            logger.error('Error updating user settings:', error);
        }
    }

    private setupSettingsMenu() {
        listenForCustomEvent(CUSTOM_EVENTS.settingsUpdated, (event: CustomEvent) => {
            this.updateUserSettings(event.detail);
        });
    }

    private setupEventListeners() {
        this.updateLoadingStatus('Setting up event listeners...');
        this.listenForConfigChanges();
        this.setupNav();
        this.setupFloatingActionButton();
        // this.setupRipple();
        this.setupWidgetDialog();
        this.setupGridContainerListeners();
        this.setupSettingsMenu();
        this.updateLoadingStatus('Event listeners set up successfully.');
    }

    private listenForConfigChanges() {
        if (!this.userId) {
            logger.error('Tried to listen for config changes without a user ID.');
            return;
        }

        listenForConfigChanges(this.userId, this.applySettings.bind(this), this.loadUserWidgets.bind(this));
    }

    private handleFabClick() {
        logger.log('Handle fab click index.ts'); // Debugging log
    }

    private setupFloatingActionButton() {
        listenForCustomEvent('fabClick', this.handleFabClick.bind(this));
    }

    private setupNav() {
        // First, set up event listeners
        listenForCustomEvent(CUSTOM_EVENTS.titleChanged, this.handleTitleChange.bind(this));
        listenForCustomEvent(CUSTOM_EVENTS.exiteditmode, this.handleExitEditMode.bind(this));

        const pageTitle = this.userConfig?.pageTitle || this.TITLE;
        logger.log("Setting up nav...", { pageTitle, userConfig: this.userConfig });

        // Update document title
        this.updateDocumentTitle(pageTitle);

        // Set up FloatingNav component
        const floatingNav = this.components.FloatingNav as FloatingNav;
        // floatingNav.setAttribute('page-title', pageTitle);
        floatingNav.updatePageTitle(pageTitle);
    }

    private updateDocumentTitle(title: string) {
        document.title = `M:S - ${title}`;
    }

    private handleTitleChange(event: CustomEvent) {
        const newTitle = event.detail;
        logger.log('Received titleChanged event:', { event, newTitle });
        this.updateUserSettings({ ...this.userConfig, pageTitle: newTitle });
        this.updateDocumentTitle(newTitle);
    }

    private handleExitEditMode() {
        logger.log('Exiting edit mode');
        this.updateUserSettings({ ...this.userConfig, editingMode: false });
    }

    private openAddWidgetDialog(row: number, column: number) {
        const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
        const dialogTitle = document.getElementById('dialog-title') as HTMLHeadingElement;
        const addButton = document.getElementById('add-widget-button') as HTMLButtonElement;
        const removeButton = document.getElementById('remove-widget-button') as HTMLButtonElement;
        const defaultWidgets = document.getElementById('default-widgets') as HTMLSelectElement;
        const widgetUrlInput = document.getElementById('widget-url') as HTMLInputElement;
        const rowInput = document.getElementById('widget-row') as HTMLInputElement;
        const columnInput = document.getElementById('widget-column') as HTMLInputElement;
        const widgetSizeSelect = document.getElementById('widget-size') as HTMLSelectElement;

        dialogTitle.textContent = 'Add a widget';
        addButton.textContent = 'Add Widget';
        removeButton.style.display = 'none';
        defaultWidgets.value = '';
        widgetUrlInput.value = '';
        rowInput.value = row.toString();
        columnInput.value = column.toString();
        widgetSizeSelect.value = '1x1';

        this.currentEditingWidgetId = null;
        widgetDialog.showModal();
    }

    private setupGridContainerListeners() {
        if (this.gridContainer) {
            this.gridContainer.addEventListener('click', (event) => {
                const target = event.target as HTMLElement;
                const gridItem = target.closest('.grid-item') as HTMLElement;
                if (gridItem && !gridItem.querySelector('.widget-container')) {
                    const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
                    if (widgetDialog) {
                        this.openAddWidgetDialog(parseInt(gridItem.dataset.row || '1'), parseInt(gridItem.dataset.column || '1'));
                    }
                }
            });
        }
    }

    private async handleRemoveWidget() {
        if (this.currentEditingWidgetId && this.userId) {
            await removeUserWidget(this.userId, this.currentEditingWidgetId);
            await this.loadUserWidgets();
            const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
            widgetDialog.close();
        }
    }

    private async addCustomWidget(widgetConfig: any) {
        if (widgetConfig.url) {
            // Add custom widget script to the dom
        }
    }

    private async handleAddOrUpdateWidget(event: Event) {
        event.preventDefault();
        const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
        const defaultWidgets = document.getElementById('default-widgets') as HTMLSelectElement;
        const widgetUrlInput = document.getElementById('widget-url') as HTMLInputElement;
        const rowInput = document.getElementById('widget-row') as HTMLInputElement;
        const columnInput = document.getElementById('widget-column') as HTMLInputElement;
        const widgetSizeSelect = document.getElementById('widget-size') as HTMLSelectElement;

        const selectedWidget = defaultWidgets.value;
        const customWidgetUrl = widgetUrlInput.value.trim();
        const row = parseInt(rowInput.value, 10);
        const column = parseInt(columnInput.value, 10);
        const [widthSpan, heightSpan] = widgetSizeSelect.value.split('x').map(Number);

        if (!isNaN(row) && !isNaN(column)) {
            let widgetConfig: any = {
                id: this.currentEditingWidgetId || generateAlphanumericId(),
                row,
                column,
                widthSpan,
                heightSpan
            };

            if (selectedWidget) {
                widgetConfig.name = selectedWidget;
            } else if (customWidgetUrl) {
                widgetConfig.name = 'custom';
                widgetConfig.url = customWidgetUrl;

                this.addCustomWidget(widgetConfig);
            } else {
                alert('Please select a widget or provide a custom URL.');
                return;
            }

            if (this.currentEditingWidgetId) {
                await this.updateWidget(this.currentEditingWidgetId, widgetConfig);
            } else {
                await this.addWidget(widgetConfig);
            }

            this.currentEditingWidgetId = null;
            widgetDialog.close();
            this.resetWidgetDialog();
        } else {
            alert('Please provide valid row and column numbers.');
        }
    }

    private resetWidgetDialog() {
        const dialogTitle = document.getElementById('dialog-title') as HTMLHeadingElement;
        const addButton = document.getElementById('add-widget-button') as HTMLButtonElement;
        const removeButton = document.getElementById('remove-widget-button') as HTMLButtonElement;
        const defaultWidgets = document.getElementById('default-widgets') as HTMLSelectElement;
        const widgetUrlInput = document.getElementById('widget-url') as HTMLInputElement;
        const rowInput = document.getElementById('widget-row') as HTMLInputElement;
        const columnInput = document.getElementById('widget-column') as HTMLInputElement;

        dialogTitle.textContent = 'Add a widget';
        addButton.textContent = 'Add Widget';
        removeButton.style.display = 'none';
        defaultWidgets.value = '';
        widgetUrlInput.value = '';
        rowInput.value = '';
        columnInput.value = '';
    }

    private setupWidgetDialog() {
        const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
        const widgetForm = document.getElementById('widget-form') as HTMLFormElement;
        const addWidgetButton = document.getElementById('add-widget-button') as HTMLButtonElement;
        const removeWidgetButton = document.getElementById('remove-widget-button') as HTMLButtonElement;
        const cancelDialogButton = document.getElementById('cancel-dialog-button') as HTMLButtonElement;

        if (widgetForm) {
            widgetForm.addEventListener('submit', this.handleAddOrUpdateWidget.bind(this));
        }

        if (removeWidgetButton) {
            removeWidgetButton.addEventListener('click', this.handleRemoveWidget.bind(this));
        }

        if (cancelDialogButton) {
            cancelDialogButton.addEventListener('click', () => {
                widgetDialog.close();
                this.resetWidgetDialog();
            });
        }

        // Close dialog when clicking outside
        widgetDialog.addEventListener('click', (event) => {
            if (event.target === widgetDialog) {
                widgetDialog.close();
                this.resetWidgetDialog();
            }
        });

        addWidgetButton.addEventListener('click', this.handleAddOrUpdateWidget.bind(this));
    }

    private createActionButton(icon: string, title: string, onClick: () => void): HTMLButtonElement {
        const button = document.createElement('button');
        button.classList.add('widget-action-button');
        button.innerHTML = icon;
        button.title = title;
        button.addEventListener('click', (e) => {
            e.stopPropagation();
            onClick();
        });
        return button;
    }

    private async updateWidget(widgetId: string, updatedWidget: any) {
        if (!this.userId || !this.userConfig) return;

        try {
            await updateUserWidget(this.userId, widgetId, updatedWidget);
            if (!this.userConfig.widgets) {
                this.userConfig.widgets = {};
            }
            this.userConfig.widgets[widgetId] = updatedWidget;
            await this.loadUserWidgets();
        } catch (error) {
            logger.error('Error updating widget:', error);
        }
    }

    private async addWidget(widgetConfig: any) {
        if (!this.userId || !this.userConfig) return;
        try {
            const mergedConfig = {
                ...widgetConfig,
                id: widgetConfig.id || generateAlphanumericId()
            };

            await updateUserWidget(this.userId, mergedConfig.id, mergedConfig);
            if (!this.userConfig.widgets) {
                this.userConfig.widgets = {};
            }
            this.userConfig.widgets[mergedConfig.id] = mergedConfig;
            await this.loadUserWidgets();
        } catch (error) {
            logger.error('Error adding widget:', error);
            alert('Failed to add widget. Please try again.');
        }
    }

    private async deleteWidget(widgetId: string) {
        if (!this.userId || !this.userConfig) return;
        try {
            logger.log(`Trying to remove ${widgetId}`);

            await removeUserWidget(this.userId, widgetId);

            if (this.userConfig.widgets) {
                delete this.userConfig.widgets[widgetId];
            }

            // TODO: only do this if it is an external widget
            widgetLoader.removeExternalWidget(widgetId);

            // Remove the widget from the DOM
            const widgetContainer = this.gridContainer!.querySelector(`.widget-container[data-widget-id="${widgetId}"]`);

            if (widgetContainer) {
                widgetContainer.remove();
                // Update grid visibility
                this.updateGridVisibility();
                logger.log(`${widgetId} was removed from the grid`, { userConfig: this.userConfig });
            }

        } catch (error) {
            logger.error('Error deleting widget:', error);
        }
    }

    private editWidget(widgetId: string) {
        if (!this.userConfig) return;
        const widget = this.userConfig.widgets[widgetId];
        if (!widget) return;

        const widgetDialog = document.getElementById('widget-dialog') as HTMLDialogElement;
        const dialogTitle = document.getElementById('dialog-title') as HTMLHeadingElement;
        const addButton = document.getElementById('add-widget-button') as HTMLButtonElement;
        const removeButton = document.getElementById('remove-widget-button') as HTMLButtonElement;
        const defaultWidgets = document.getElementById('default-widgets') as HTMLSelectElement;
        const widgetUrlInput = document.getElementById('widget-url') as HTMLInputElement;
        const rowInput = document.getElementById('widget-row') as HTMLInputElement;
        const columnInput = document.getElementById('widget-column') as HTMLInputElement;
        const widgetSizeSelect = document.getElementById('widget-size') as HTMLSelectElement;

        dialogTitle.textContent = 'Edit Widget';
        addButton.textContent = 'Update Widget';
        removeButton.style.display = 'inline-block';
        defaultWidgets.value = widget.name;
        widgetUrlInput.value = widget.url || '';
        rowInput.value = widget.row.toString();
        columnInput.value = widget.column.toString();
        widgetSizeSelect.value = `${widget.widthSpan}x${widget.heightSpan}`;

        this.currentEditingWidgetId = widgetId;
        widgetDialog.showModal();

        const handleUpdate = async (e: Event) => {
            e.preventDefault();
            const updatedWidget = {
                ...widget,
                name: defaultWidgets.value,
                url: widgetUrlInput.value,
                row: parseInt(rowInput.value, 10),
                column: parseInt(columnInput.value, 10),
                widthSpan: parseInt(widgetSizeSelect.value.split('x')[0], 10),
                heightSpan: parseInt(widgetSizeSelect.value.split('x')[1], 10)

            };
            await this.updateWidget(widgetId, updatedWidget);
            widgetDialog.close();
            addButton.removeEventListener('click', handleUpdate);
        };

        addButton.addEventListener('click', handleUpdate);

        removeButton.onclick = async () => {
            await this.deleteWidget(widgetId);
            widgetDialog.close();
        };
    }

    private async loadUserWidgets() {
        logger.log('Loading user widgets...');

        if (!this.gridContainer || !this.userConfig || !this.userConfig.widgets) {
            logger.log('No grid container or user widgets found.');
            return;
        }

        // Hide grid container
        this.gridContainer.style.visibility = 'hidden';

        // Clear ALL existing widgets
        this.gridContainer.querySelectorAll('.widget-container').forEach(widget => widget.remove());

        const widgetPromises = Object.entries(this.userConfig.widgets).map(async ([widgetId, widget]) => {
            try {

                if (!this.gridContainer || !widget) return;

                const gridItem = this.gridContainer!.querySelector(
                    `.grid-item[data-row="${widget.row}"][data-column="${widget.column}"]`
                ) as HTMLElement;

                if (gridItem) {
                    // Remove any existing widget in this grid item
                    const existingWidget = gridItem.querySelector('.widget-container');
                    if (existingWidget) {
                        logger.warn(`Removing unexpected widget in grid item ${widget.row},${widget.column}`);
                        existingWidget.remove();
                    }

                    logger.log(`Loading widget ${widgetId}...`);
                    const widgetElement = await widgetLoader.loadWidget(widget);
                    const widgetContainer = document.createElement('div');
                    widgetContainer.classList.add('widget-container');
                    widgetContainer.dataset.widgetId = widgetId;

                    // Set the grid area based on the widget's size
                    widgetContainer.style.gridArea = `${widget.row} / ${widget.column} / span ${widget.heightSpan} / span ${widget.widthSpan}`;

                    widgetContainer.appendChild(widgetElement);

                    const widgetActions = document.createElement('div');
                    widgetActions.classList.add('widget-actions');

                    const editButton = this.createActionButton('✏️', 'Edit widget', () => this.editWidget(widgetId));
                    const deleteButton = this.createActionButton('🗑️', 'Delete widget', () => this.deleteWidget(widgetId));

                    widgetActions.appendChild(editButton);
                    widgetActions.appendChild(deleteButton);

                    widgetContainer.appendChild(widgetActions);
                    this.gridContainer.appendChild(widgetContainer);

                    logger.log(`Widget ${widgetId} loaded successfully.`);
                }
            } catch (error) {
                logger.error(`Error loading widget ${widgetId}:`, error);
                this.createErrorWidget(widget, widgetId);
            }
        });

        // Wait for all widgets to load
        await Promise.all(widgetPromises);

        logger.log('Finished loading user widgets.');
        this.updateGridVisibility();

        // Show grid container
        this.gridContainer.style.visibility = 'visible';
    }

    private createErrorWidget(widget: any, widgetId: string) {
        const gridItem = this.gridContainer!.querySelector(
            `.grid-item[data-row="${widget.row}"][data-column="${widget.column}"]`
        ) as HTMLElement;

        if (gridItem) {
            // Remove any existing widgets in this grid item
            const existingWidget = gridItem.querySelector('.widget-container');
            if (existingWidget) {
                existingWidget.remove();
            }

            const errorWidget = document.createElement('div');
            errorWidget.classList.add('widget-error');
            errorWidget.textContent = `Error loading widget: ${widget.name}`;

            const errorContainer = document.createElement('div');
            errorContainer.classList.add('widget-container');
            errorContainer.dataset.widgetId = widgetId;
            errorContainer.appendChild(errorWidget);

            gridItem.appendChild(errorContainer);
        }
    }

    private updateGridVisibility() {
        if (!this.gridContainer) return;

        logger.log(`Updating grid visibility. Show grid: ${this.showGrid}`);

        document.body.classList.toggle('show-grid', this.showGrid);

        const gridItems = this.gridContainer.querySelectorAll('.grid-item');
        gridItems.forEach((item: any) => {
            const hasWidget = item.querySelector('.widget-container') !== null;

            // Always display the grid item when showGrid is true
            if (this.showGrid) {
                item.style.display = 'flex';
                item.classList.add('grid-visible');
            } else {
                // When showGrid is false, only display if it has a widget
                item.style.display = hasWidget ? 'flex' : 'none';
                item.classList.remove('grid-visible');
            }
        });
    }

    private renderGrid() {
        if (!this.gridContainer) return;

        this.gridContainer.innerHTML = '';

        for (let row = 1; row <= this.rows; row++) {
            for (let col = 1; col <= this.columns; col++) {
                const gridItem = document.createElement('div');
                gridItem.classList.add('grid-item');
                if (this.showGrid) {
                    gridItem.classList.add('grid-visible');
                }
                gridItem.dataset.row = row.toString();
                gridItem.dataset.column = col.toString();
                gridItem.style.gridArea = `${row} / ${col} / span 1 / span 1`;
                this.gridContainer.appendChild(gridItem);
            }
        }

        // Update grid visibility after rendering
        this.updateGridVisibility();

        // Load widgets after grid is created
        this.loadUserWidgets();
    }

    private updateGridLayout() {
        if (!this.gridContainer) return;

        this.gridContainer.style.gridTemplateColumns = `repeat(${this.columns}, 1fr)`;
        this.gridContainer.style.gridTemplateRows = `repeat(${this.rows}, 1fr)`;
        this.gridContainer.style.gap = `${this.gap}px`;

        this.renderGrid(); // This will now also load widgets and update visibility
    }

    private applySettings(settings: {
        columns?: number;
        rows?: number;
        gap?: number;
        showGrid?: boolean;
        theme?: string;
    }) {
        let configChanged = false;

        if (settings.columns !== undefined && settings.columns !== this.columns) {
            this.columns = settings.columns;
            configChanged = true;
        }
        if (settings.rows !== undefined && settings.rows !== this.rows) {
            this.rows = settings.rows;
            configChanged = true;
        }
        if (settings.gap !== undefined && settings.gap !== this.gap) {
            this.gap = settings.gap;
            configChanged = true;
        }
        if (settings.theme !== undefined && settings.theme !== this.theme) {
            this.theme = settings.theme;
            configChanged = true;
        }
        if (settings.showGrid !== undefined && settings.showGrid !== this.showGrid) {
            this.showGrid = settings.showGrid;
            configChanged = true;
        }

        logger.log('Settings applied:', settings); // Debug log

        // Apply theme
        document.body.classList.toggle('dark-theme', this.theme === 'dark');

        // Update grid layout if necessary
        if (settings.columns !== undefined || settings.rows !== undefined || settings.gap !== undefined) {
            this.updateGridLayout();
        }

        // Always update grid visibility when applying settings
        this.updateGridVisibility();

        // If any config has changed, update Firebase
        if (configChanged && this.userId) {
            updateUserConfig(this.userId, {
                columns: this.columns,
                rows: this.rows,
                gap: this.gap,
                showGrid: this.showGrid,
                theme: this.theme
            });
        }
    }

    async handleFirstTimeSubmit(email) {
        const dialog = document.getElementById('first-time-dialog');

        if (email && await checkEmailUniqueness(email)) {
            const userId = generateAlphanumericId();
            const userConfig = {
                email: email,
                id: userId,
                config: {
                    theme: 'light',
                    language: 'en',
                    widgets: {}
                }
            };
            await addUserToFirebase(userConfig);
            localStorage.setItem('user_profile', userId);
            this.userId = userId;
            this.userConfig = userConfig.config;

            // Show a success message inside of dialog, then setTimeout to close dialog 1 second later
            if (!dialog) {
                logger.error('First time dialog not found.');
                return;
            }

            dialog.innerHTML = `<p>Great, you're all set, let's get started!</p>`;

            setTimeout(() => {
                dialog.innerHTML = `
                    <p>We are going to generate an ID that corresponds to this moon</p>
                    <p>moon is just what we call dashboards for your TV</p>
                `;
            }, 1000);

            setTimeout(() => {
                dialog.innerHTML = `
                    <p>Your Moon ID is: <strong>${userId}</strong></p>
                    <p>Enter this ID in your TV</p>
                `;
            }, 3500);

            setTimeout(() => {
                dialog.innerHTML = '';
                dialog.style.display = 'none';
                this.displayUserId();
            }, 7000);


        } else {
            alert('Email is already in use. Please try another one.');
        }
    }

    registerFirstTimeListeners() {
        const submitEmailButton = document.getElementById('submit-email');
        const emailInput = document.getElementById('email-input') as HTMLInputElement;

        if (!emailInput || !submitEmailButton) {
            logger.error('First time user input or submit button not found.');
            return;
        }

        emailInput.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' && emailInput.value) {
                this.handleFirstTimeSubmit(emailInput.value);
            }
        });


        submitEmailButton.addEventListener('click', async () => {
            if (emailInput.value) {
                this.handleFirstTimeSubmit(emailInput.value);
            }

        });
    }

    showFirstTimeUserDialog() {
        const dialog = document.createElement('div');
        dialog.id = 'first-time-dialog';
        dialog.classList.add('dialog-bottom-left');
        dialog.innerHTML = `
        <h1>Welcome to Moon Suite Live</h1>
        <p>Moon suite let's you create an interactive dashboard for your TV.</p>
        <p>Watch it update on your TV in real time.</p>
        <button id="get-started" class="get-started-btn">Let's Get Started</button>
    `;
        document.body.appendChild(dialog);

        const getStartedButton = document.getElementById('get-started');

        getStartedButton?.addEventListener('click', () => {
            dialog.innerHTML = `
            <p>Please enter your email:</p>
            <input type="email" id="email-input" />
            <button id="submit-email">Submit</button>
        `;

            this.registerFirstTimeListeners();
        });
    }

    private removeLoadingSpinner() {
        document.body.removeChild(this.components.LoadingSpinner);
    }
}

// Initialize the application
document.addEventListener('DOMContentLoaded', () => {
    const app = new MoonSuiteBuilder();
    app.init();
});