export class LoginForm extends HTMLElement {
    constructor() {
        super();

        // Create a shadow root
        const shadow = this.attachShadow({ mode: 'open' });

        // Create the login form container
        const loginFormContainer = document.createElement('div');
        loginFormContainer.id = 'loginForm';

        // Create the email input
        const emailInput = document.createElement('input');
        emailInput.type = 'email';
        emailInput.id = 'email';
        emailInput.placeholder = 'Enter your email';
        // loginFormContainer.appendChild(emailInput);

        // Create the request OTP button
        const requestOTPButton = document.createElement('button');
        requestOTPButton.id = 'requestOTP';
        requestOTPButton.textContent = 'Request OTP';
        // loginFormContainer.appendChild(requestOTPButton);

        // Create the OTP field container
        const otpFieldContainer = document.createElement('div');
        otpFieldContainer.id = 'otpField';
        otpFieldContainer.style.display = 'none';

        // Create the OTP input
        const otpInput = document.createElement('input');
        otpInput.type = 'text';
        otpInput.id = 'otp';
        otpInput.placeholder = 'Enter OTP';
        // otpFieldContainer.appendChild(otpInput);

        // Create the verify OTP button
        const verifyOTPButton = document.createElement('button');
        verifyOTPButton.id = 'verifyOTP';
        verifyOTPButton.textContent = 'Verify OTP';
        // otpFieldContainer.appendChild(verifyOTPButton);

        // Append the OTP field container to the login form container
        // loginFormContainer.appendChild(otpFieldContainer);

        // Append the login form container to the shadow root
        shadow.appendChild(loginFormContainer);
    }
}