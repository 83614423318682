import logger from "../utils/logger";

export class LoadingSpinner extends HTMLElement {
    private spinnerElement: HTMLDivElement;
    private textElement: HTMLParagraphElement;
    private hideTimeout: number | null = null;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });

        const style = document.createElement('style');
        style.textContent = `
            :host {
                display: none;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(245, 245, 245, 0.9);
                z-index: 9999;
            }
            :host(.visible) {
                display: flex;
            }
            .spinner {
                width: 50px;
                height: 50px;
                border: 5px solid #f3f3f3;
                border-top: 5px solid #3498db;
                border-radius: 50%;
                animation: spin 1s linear infinite;
            }
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
            p {
                color: darkgray;
                margin-top: 20px;
                font-family: Arial, sans-serif;
            }
        `;

        this.spinnerElement = document.createElement('div');
        this.spinnerElement.classList.add('spinner');

        this.textElement = document.createElement('p');
        this.textElement.textContent = 'Loading...';

        this.shadowRoot?.appendChild(style);
        this.shadowRoot?.appendChild(this.spinnerElement);
        this.shadowRoot?.appendChild(this.textElement);

        // Add the spinner to the body when it's created
        document.body.appendChild(this);

        // Bind methods to the class instance
        this.updateText = this.updateText.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.delayedHide = this.delayedHide.bind(this);
        this.cancelDelayedHide = this.cancelDelayedHide.bind(this);
        this.remove = this.remove.bind(this);
    }

    updateText(text: string) {
        logger.log(text);
        if (this?.textElement) {
            this.textElement.textContent = text;
        }
    }

    show() {
        this.classList.add('visible');
    }

    hide() {
        this.classList.remove('visible');
    }

    delayedHide(delay: number) {
        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
        }
        this.hideTimeout = window.setTimeout(() => {
            this.hide();
            this.hideTimeout = null;
        }, delay);
    }

    cancelDelayedHide() {
        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
        }
    }

    // Method to remove the spinner from the DOM
    remove() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    }
}