import { CUSTOM_EVENTS } from '../utils/const';
import { generateAlphanumericId } from '../utils/idGenerator';
import { dispatchCustomEvent } from "../utils/customEvents";
import logger from '../utils/logger';

export class WidgetDialog extends HTMLElement {
    private form: HTMLFormElement | null = null;
    private dialog: HTMLDialogElement | null = null;
    private currentEditingWidgetId: string | null = null;
    private initialized: boolean = false;

    constructor() {
        super();
        logger.log('WidgetDialog constructor called');
    }

    connectedCallback() {
        logger.log('WidgetDialog connectedCallback called');
        this.initializeContent();
    }

    private async initializeContent() {
        this.dialog = document.getElementById('widget-dialog') as HTMLDialogElement;
        if (!this.dialog) {
            logger.error('Dialog element not found in the document');
            return;
        }

        this.innerHTML = this.createFormHTML();
        this.form = this.querySelector('#widget-form') as HTMLFormElement;

        if (!this.form) {
            logger.error('Form element not found after initialization');
            return;
        }

        this.setupEventListeners();
        this.initialized = true;
        logger.log('Dialog content initialized');
    }

    private createFormHTML(): string {
        return /*html*/`
            <form id="widget-form" method="dialog">
                <div class="md-dialog-content">
                    <h2 id="dialog-title" class="md-dialog-title">Add a widget</h2>
                    <div class="md-form-field">
                        <select id="default-widgets" name="default-widgets" class="md-select">
                            <option value="">Select a widget</option>
                            <option value="clock">Digital Clock</option>
                            <option value="slideshow">Picture Slideshow</option>
                            <option value="weather">Weather</option>
                        </select>
                        <label for="default-widgets">Widget Type</label>
                    </div>
                    <div class="md-form-field">
                        <input type="text" id="widget-url" name="widget-url" class="md-input" placeholder=" ">
                        <label for="widget-url">Custom Widget URL</label>
                    </div>
                    <div class="md-form-field">
                        <select id="widget-size" name="widget-size" class="md-select">
                            <option value="1x1">1x1</option>
                            <option value="2x1">2x1</option>
                            <option value="3x2">3x2</option>
                        </select>
                        <label for="widget-size">Widget Size</label>
                    </div>
                    <div class="md-form-field md-form-field-inline">
                        <input type="number" id="widget-row" name="widget-row" class="md-input" placeholder=" ">
                        <label for="widget-row">Row</label>
                    </div>
                    <div class="md-form-field md-form-field-inline">
                        <input type="number" id="widget-column" name="widget-column" class="md-input" placeholder=" ">
                        <label for="widget-column">Column</label>
                    </div>
                </div>
                <div class="md-dialog-actions">
                    <button type="button" id="cancel-dialog-button" class="md-button">Cancel</button>
                    <button type="button" id="remove-widget-button" class="md-button md-button-warning" style="display: none;">Remove</button>
                    <button type="submit" id="add-widget-button" class="md-button md-button-primary">Add Widget</button>
                </div>
            </form>
        `;
    }

    private setupEventListeners() {
        if (!this.form) return;

        this.form.addEventListener('submit', this.handleSubmit.bind(this));
        this.querySelector('#cancel-dialog-button')?.addEventListener('click', this.hide.bind(this));
        this.querySelector('#remove-widget-button')?.addEventListener('click', this.handleRemove.bind(this));
    }

    async show(row?: number, column?: number, widgetId?: string) {
        logger.log('Show method called');

        if (!this.initialized) {
            logger.warn('WidgetDialog not yet initialized. Initializing now...');
            await this.initializeContent();
        }

        if (!this.form || !this.dialog) {
            logger.error('WidgetDialog not properly initialized');
            return;
        }

        this.resetForm();

        if (row !== undefined && column !== undefined) {
            const rowInput = this.form.querySelector('#widget-row') as HTMLInputElement;
            const columnInput = this.form.querySelector('#widget-column') as HTMLInputElement;

            if (!rowInput || !columnInput) {
                logger.error('Row and column inputs not found in the form');
                return;
            }

            logger.log("Widget all inputs found", document.querySelectorAll('input'));
            rowInput.disabled = true;
            rowInput.value = row;
            columnInput.disabled = true;
            columnInput.value = column;
        }

        const dialogTitle = this.querySelector('#dialog-title');
        const addButton = this.querySelector('#add-widget-button');
        const removeButton = this.querySelector('#remove-widget-button');

        if (widgetId) {
            // TODO: row and column inputs should not be less than 1, and should not exceed the amount of columns or rows available
            this.currentEditingWidgetId = widgetId;
            if (dialogTitle) dialogTitle.textContent = 'Edit widget';
            if (addButton) (addButton as HTMLButtonElement).textContent = 'Update Widget';
            if (removeButton) (removeButton as HTMLButtonElement).style.display = 'inline-block';
            // Here you would typically load the widget data and populate the form
        } else {
            this.currentEditingWidgetId = null;
            if (dialogTitle) dialogTitle.textContent = 'Add a widget';
            if (addButton) (addButton as HTMLButtonElement).textContent = 'Add Widget';
            if (removeButton) (removeButton as HTMLButtonElement).style.display = 'none';
        }

        logger.log('Calling showModal');
        this.dialog.showModal();
    }

    hide() {
        if (this.dialog) {
            this.dialog.close();
        }
        this.resetForm();
    }

    private resetForm() {
        if (this.form) {
            this.form.reset();
        }
        this.currentEditingWidgetId = null;
    }

    private handleSubmit(event: Event) {
        event.preventDefault();
        if (!this.form) return;

        const formData = new FormData(this.form);
        const widgetConfig = {
            id: this.currentEditingWidgetId || generateAlphanumericId(),
            name: formData.get('default-widgets') as string,
            url: formData.get('widget-url') as string,
            row: parseInt(formData.get('widget-row') as string, 10),
            column: parseInt(formData.get('widget-column') as string, 10),
            size: formData.get('widget-size') as string
        };

        if (!widgetConfig.name && !widgetConfig.url) {
            alert('Please select a widget or provide a custom URL.');
            return;
        }

        const eventName = this.currentEditingWidgetId ? CUSTOM_EVENTS.widgetUpdated : CUSTOM_EVENTS.widgetAdded;
        dispatchCustomEvent(eventName, widgetConfig);
        this.hide();
    }

    private handleRemove() {
        if (this.currentEditingWidgetId) {
            dispatchCustomEvent(CUSTOM_EVENTS.widgetRemoved, { id: this.currentEditingWidgetId });
            this.hide();
        }
    }
}

customElements.define('widget-dialog', WidgetDialog);