import moon from '../assets/moon.png';
import { CUSTOM_EVENTS } from '../utils/const';
import { dispatchCustomEvent } from '../utils/customEvents';
import logger from '../utils/logger';

export class FloatingNav extends HTMLElement {
    private navContainer: HTMLDivElement;
    private logo: HTMLImageElement;
    private siteName: HTMLSpanElement;
    private pageTitle: HTMLSpanElement;
    private editIcon: HTMLSpanElement;

    constructor() {
        super();
        this.init();
        logger.log('FloatingNav instance created.');
    }

    private init() {
        const shadow = this.attachShadow({ mode: 'open' });

        const style = document.createElement('style');
        style.textContent = `
            :host {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 90px;
                transition: all 0.3s ease;
                z-index: 1000;
            }
            .nav-container {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 20px;
            }
            .logo {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                transition: all 0.3s ease;
            }
            .site-name {
                font-size: 24px;
                font-weight: bold;
                margin-right: 10px;
            }
            .hyphen {
                margin: 0 10px;
            }
            .page-title {
                font-size: 20px;
                outline: none;
                cursor: pointer;
                transition: all 0.3s ease;
                border-bottom: 2px solid transparent;
            }
            .page-title:hover {
                border-bottom: 2px solid #2196F3;
            }
            .edit-icon {
                margin-left: 5px;
                cursor: pointer;
            }
            :host(:hover) {
                backdrop-filter: blur(5px);
            }
            :host(:hover) .logo {
                transform: rotate(360deg);
            }
        `;

        this.navContainer = document.createElement('div');
        this.navContainer.classList.add('nav-container');
        this.navContainer.id = 'floating-nav';

        this.pageTitle = document.createElement('span');
        this.pageTitle.classList.add('page-title');
        this.pageTitle.setAttribute('contenteditable', 'true');
        this.pageTitle.textContent = 'My Moon';



        this.logo = document.createElement('img');
        this.logo.src = moon;
        this.logo.alt = 'Logo';
        this.logo.classList.add('logo');

        this.siteName = document.createElement('span');
        this.siteName.classList.add('site-name');
        this.siteName.textContent = 'M:S';

        const hyphen = document.createElement('span');
        hyphen.classList.add('hyphen');
        hyphen.textContent = '-';

        this.editIcon = document.createElement('span');
        this.editIcon.classList.add('edit-icon');
        this.editIcon.textContent = '✏️';

        this.navContainer.appendChild(this.logo);
        this.navContainer.appendChild(this.siteName);
        this.navContainer.appendChild(hyphen);
        this.navContainer.appendChild(this.pageTitle);
        this.navContainer.appendChild(this.editIcon);

        this.shadowRoot?.appendChild(style);

        shadow.appendChild(this.navContainer)
        this.setupEventListeners();
    }

    private setupEventListeners() {
        this.pageTitle.addEventListener('input', () => {
            this.updatePageTitle(this.pageTitle.textContent || '');
        });

        this.pageTitle.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.pageTitle.blur();
                dispatchCustomEvent(CUSTOM_EVENTS.exiteditmode, event);
            }
        });

        this.pageTitle.addEventListener('blur', (event) => {
            dispatchCustomEvent(CUSTOM_EVENTS.exiteditmode, event);
        });
    }

    connectedCallback() {
        logger.log('FloatingNav connected to the DOM');
    }


    public updatePageTitle(title: string) {
        logger.log('Updating FloatingNav page title to:', title);
        if (this.pageTitle) {
            this.pageTitle.textContent = title;
            this.forceUpdate();
        }

        dispatchCustomEvent(CUSTOM_EVENTS.titleChanged, title, { targetElement: this });
    }

    public getPageTitle(): string {
        return this.pageTitle.textContent || '';
    }

    private forceUpdate() {
        // Force a re-render of the shadow DOM
        if (!this.shadowRoot) return;

        const oldNode = this.shadowRoot.firstChild;

        if (!oldNode) return;
        const newNode = oldNode.cloneNode(true);
        this.shadowRoot.replaceChild(newNode, oldNode);

        // Update the reference to pageTitle
        if (!this.pageTitle) return;
        this.pageTitle = this.shadowRoot.querySelector('.page-title') as any;
    }

}
